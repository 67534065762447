import VueRoute from 'vue-router'
import Vue from 'vue'
import routerMap from './routerMap'

const routerPush = VueRoute.prototype.push
VueRoute.prototype.push = function push(location) {//防止重新加载相同的路由时控制台报错
    return routerPush.call(this, location).catch(error=> error)
}

Vue.use(VueRoute)

const routes = [
    {
        path:'', name: 'login', meta: { title: "评级后台"},
        component: resolve => require(['@/views/Login/index'], resolve),
    },
    {
        path:'/home',
        name:'home',
        meta:{},
        children: routerMap,
        component: resolve => require(['@/views/Home/index'], resolve)
    }
]

const router = new VueRoute({
    // 配置路由和组件之间的应用关系
    routes,
    mode: 'hash',
    linkActiveClass: 'active'
})
router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title === undefined ?'站点后台':to.meta.title

    if(to.path === '/'){
        next();
    }else{//此处可以做一些逻辑判断，例如权限，token验证
        let token = localStorage.getItem('token');
        if(!token) {
            next('/');
        }else{
            next();
        }
    }
});

export default router