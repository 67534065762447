export default [
    {
        path: '/takeOverProductLinePage',
        name: 'takeOverProductLinePage',
        meta: { title: "收货记录" },
        component: resolve => require(['@/views/TakeOverManage/TakeOverLinePage/index'], resolve)
    },
    {
        path: '/confirmTakeOverProductPage',
        name: 'confirmTakeOverProductPage',
        meta: { title: "确认收货"},
        component: resolve => require(['@/views/TakeOverManage/TakeOverConfirmPage/index'], resolve)
    },
]