export default [
    {
        path: '/checkListPage',
        name: 'checkListPage',
        meta: { title: "鉴定管理" },
        component: resolve => require(['@/views/CheckManage/CheckListPage'], resolve)
    },
    {
        path: '/checkActionPage',
        name: 'checkActionPage',
        meta: { title: "鉴定操作"},
        component: resolve => require(['@/views/CheckManage/CheckActionPage/index'], resolve)
    },
]