var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.show ? "loading-box" : "loading-box-hide" },
    [
      _c("transition", { attrs: { name: "bounce" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show",
              },
            ],
            staticClass: "loading-padding",
          },
          [
            _c("atom-spinner", {
              attrs: { "animation-duration": 1000, size: 60, color: "#ff1d5e" },
            }),
            _c("span", [_vm._v("加载中...")]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }