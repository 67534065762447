<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>

<style lang='scss'>
@import "assets/ base.css";
html,body,#app {width:100%;height:100%;}
.ant-pagination-simple-pager > input {
  pointer-events: none !important;
}
.ant-popover-inner-content button {
  height: 40px !important;
  padding: 5px 15px !important;
}
</style>
