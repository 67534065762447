import CheckRouter from "@/router/routerMap/CheckRouter"
import SendRouter from "@/router/routerMap/SendRouter"
import SettlementRouter from "@/router/routerMap/SettlementRouter"
import TakeOverRouter from "@/router/routerMap/TakeOverRouter"

export default [
    ...SendRouter,
    ...CheckRouter,
    ...SettlementRouter,
    ...TakeOverRouter
]